
import { Component, Vue, Prop, Watch, Mixins } from "vue-property-decorator";
import { Notify } from "quasar";
import TerminosYCondicionesComponent from "@/components/Common/TerminosYCondicionesComponent.vue";
import FormValidator from "@/components/mixins/FormValidator.ts";

interface SignupData {
  name: string;
  last_name: string;
  second_name: string;
  sexo: number | null;
  fecha_nacimiento: Date | null;
  rut: string;
  email: string;
  confirm_email: string;
  phone: number | null;
  calle: string;
  comuna: string;
  numero: string;
  aclaratoria: string | null;
  password: string;
  password_confirmation: string;
}

interface Tipos {
  value: number | any;
  label: string;
}

@Component({
  components: {
    TerminosYCondicionesComponent
  }
})
export default class RegisterComponent extends Mixins(FormValidator) {
  private formattedRut: string = "";
  private loading = false;
  private dialogTerms = false;
  private terms: boolean = false;
  private customModel = "no";
  private bTerminos = false;
  private selectTipo: Array<any> = [];
  private streetsListOptions: Array<any> = [];
  private streetsList: any[] = [];
  private comunasListOptions: Array<any> = [];
  private comunasList: any[] = [];
  private terminosFooter = false;
  private formattedFecha = "";
  private edad = 0;
  protected formData: SignupData = {
    name: "",
    last_name: "",
    second_name: "",
    sexo: null as number | null,
    fecha_nacimiento: null as Date | null,
    rut: "",
    email: "",
    confirm_email: "",
    phone: null,
    calle: "",
    comuna: "",
    numero: "",
    aclaratoria: "",
    password: "",
    password_confirmation: ""
  };

  private datosTipo: Tipos = {
    value: null,
    label: "Género"
  };
  private dataCalle: Tipos = {
    value: null,
    label: ""
  };

  private dataComuna: Tipos = {
    value: 108,
    label: "La Reina"
  };

  private localeCalendar = {
    days: [
      "Domingo",
      "Lunes",
      "Martes",
      "Miércoles",
      "Jueves",
      "Viernes",
      "Sábado"
    ],
    daysShort: ["Dom", "Lun", "Mar", "Mie", "Jue", "Vie", "Sab"],
    months: [
      "Enero",
      "Febrero",
      "Marzo",
      "Abril",
      "Mayo",
      "Junio",
      "Julio",
      "Agosto",
      "Septiembre",
      "Octubre",
      "Noviembre",
      "Diciembre"
    ],
    monthsShort: [
      "Ene",
      "Feb",
      "Mar",
      "Abr",
      "May",
      "Jun",
      "Jul",
      "Ago",
      "Sep",
      "Oct",
      "Nov",
      "Dic"
    ]
  };

  private datosTipoIdentificacion: Tipos = {
    value: 1,
    label: "Cédula de identidad (Rut)"
  };
  private selectTipoIdentificacion: Array<any> = [
    { value: 1, label: "Cédula de identidad (Rut)" }
  ];
  private tipoDocumento = 1;

  private disabled = true;

  @Watch("datosTipoIdentificacion")
  private identificacionTipo(newValue: any) {
    this.tipoDocumento = newValue.value;
    this.formData.rut = "";
    this.formattedRut = "";
    this.disabled = false;
  }
  @Watch("datosTipo")
  private sexoTipo(newValue: any) {
    this.formData.sexo = newValue.value;
  }

  @Watch("formattedRut")
  private formatRut(newValue: string) {
    newValue = newValue.replace(/\./g, "").replace(/-/g, "");
    this.formData.rut = newValue;
    if (newValue.length > 5) {
      const rut = newValue
        .substring(0, newValue.length - 1)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
      const dv = newValue.substring(newValue.length - 1).toUpperCase();
      this.formattedRut = `${rut}-${dv}`;
    } else {
      this.formattedRut = newValue
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.")
        .toUpperCase();
    }
  }

  @Watch("dataCalle")
  private calleWatcher(newValue: any) {
    this.formData.calle = newValue.label;
  }

  @Watch("dataComuna")
  private comunaWatcher(newValue: any) {
    this.formData.comuna = newValue.label;
  }

  @Watch("formattedFecha")
  private edadWatcher(newValue: any) {
    const now = new Date(Date.now());
    if (typeof newValue == "object") {
      let day = newValue.getDate();
      let month = newValue.getMonth() + 1;
      let year = newValue.getFullYear();
      if (month < 10) {
        if (day < 10) {
          newValue = `0${day}/0${month}/${year}`;
        } else {
          newValue = `${day}/0${month}/${year}`;
        }
      } else {
        if (day < 10) {
          newValue = `0${day}/${month}/${year}`;
        } else {
          newValue = `${day}/${month}/${year}`;
        }
      }
    }
    var birthDate: any = "";
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (ua.indexOf("chrome") > -1) {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          " 12:00:00") as any;
      } else {
        birthDate = (newValue.substring(6, 10) +
          "-" +
          newValue.substring(3, 5) +
          "-" +
          newValue.substring(0, 2) +
          "T12:00:00") as any;
      }
    } else if (ua.indexOf("mozilla") != -1) {
      //Usa Mozilla
      birthDate = (newValue.substring(6, 10) +
        "-" +
        newValue.substring(3, 5) +
        "-" +
        newValue.substring(0, 2) +
        "T12:00:00") as any;
    }
    if (birthDate && birthDate.length <= 18) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.FORMAT_BIRTH").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      this.formData.fecha_nacimiento = null;
      return;
    }
    let diff;
    if (birthDate instanceof Date) {
      diff = now.getTime() - birthDate.getTime();
      this.formData.fecha_nacimiento = birthDate;
    } else {
      diff = now.getTime() - new Date(birthDate).getTime();
      this.formData.fecha_nacimiento = new Date(birthDate);
    }
  }

  private mounted() {
    this.selectTipo.push(
      { value: 0, label: this.$t("fields.sex-female").toString() },
      { value: 1, label: this.$t("fields.sex-male").toString() },
      { value: 2, label: this.$t("fields.sex-other").toString() },
      { value: 3, label: "Prefiero no contestar" }
    );
    this.getComunas();
    this.formData.comuna = "La Reina";
  }

  private getCalles() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("calles")
      .then(res => {
        this.$q.loading.hide();
        res.data.forEach((street: any) => {
          this.streetsList.push(street);
        });
        this.loading = false;
      })
      .catch(error => {
        this.$q.loading.hide();
        this.loading = false;
      });
  }

  private getComunas() {
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.$axios
      .get("comunas")
      .then(res => {
        this.$q.loading.hide();
        res.data.forEach((comuna: any) => {
          this.comunasList.push(comuna);
        });
        this.loading = false;
      })
      .catch(error => {
        this.$q.loading.hide();
        this.loading = false;
      });
  }

  private registrar() {
    if (
      !this.formData.rut ||
      (!this.validateRut(this.formData.rut) && this.tipoDocumento == 1) ||
      this.formData.rut.trim() == ""
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_RUT").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (!this.formData.name || this.formData.name.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_NAME").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (!this.formData.last_name || this.formData.last_name.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_APELLIDOS").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (this.formData.second_name == "") {
      this.formData.second_name = "";
    }

    if (
      this.formData.fecha_nacimiento == null ||
      !this.checkFecha(this.formData.fecha_nacimiento)
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_BIRTH").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.checkFechaMayorAHoy(this.formData.fecha_nacimiento)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: "La fecha de nacimiento no puede ser mayor a la actual.",
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.validFecha(this.formData.fecha_nacimiento)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.form").toString(),
        text: this.$t("notifications.error.FORMAT_BIRTH").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf("safari") != -1) {
      if (!(ua.indexOf("chrome") > -1)) {
        if (this.formData.fecha_nacimiento.getFullYear() < 1890) {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.FORMAT_BIRTH").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
          return;
        }
      }
    }

    if (this.formData.sexo == null) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_GENERO").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (!this.formData.email || this.formData.email.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_EMAIL").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (this.formData.email != this.formData.confirm_email) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: "Los correos no coinciden",
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (!this.validEmail(this.formData.email)) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.FORMAT_EMAIL").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (!this.formData.phone || this.formData.phone == 0) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_PHONE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (this.formData.phone && this.formData.phone <= 99999999) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.FORMAT_PHONE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (!this.formData.calle || this.formData.calle.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_CALLE").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.formData.numero || this.formData.numero.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.NO_NUMERO").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (!this.formData.comuna || this.formData.comuna.trim() == "") {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: "Por favor, seleccione una comuna.",
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    if (
      !this.formData.password ||
      this.formData.password.trim() == "" ||
      this.formData.password.length < 6
    ) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("messages.password_length_message").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (this.formData.password != this.formData.password_confirmation) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("messages.not_match_password").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }

    if (this.customModel == "no" || !this.customModel) {
      this.$swal({
        icon: "error",
        title: this.$t("notifications.titles.register").toString(),
        text: this.$t("notifications.error.ERROR_TERMINOS").toString(),
        confirmButtonText: this.$t("buttons.accept").toString()
      });
      return;
    }
    this.$q.loading.show({
      delay: 100 // ms
    });
    this.loading = true;
    const rut = this.formData.rut.substring(0, this.formData.rut.length - 1);
    const dv = this.formData.rut.substring(this.formData.rut.length - 1);
    const formattedRut = `${rut}-${dv}`;
    if (this.tipoDocumento == 1) {
      const rut = this.formData.rut.substring(0, this.formData.rut.length - 1);
      const dv = this.formData.rut.substring(this.formData.rut.length - 1);
      const formattedRut = `${rut}-${dv}`;
      this.formData.rut = formattedRut;
    } else {
      const formattedDoc = this.formData.rut.replace("-", "");
      this.formData.rut = formattedDoc;
    }
    this.$axios
      .post("user/registro", {
        username:
          this.formData.name +
          " " +
          this.formData.last_name +
          " " +
          this.formData.second_name,
        fecha_nacimiento: this.formData.fecha_nacimiento,
        sexo: this.formData.sexo,
        rut: this.formData.rut,
        email: this.formData.email,
        calle: this.formData.calle,
        numero: this.formData.numero,
        comuna: this.formData.comuna,
        aclaratoria: this.formData.aclaratoria,
        telefono: this.formData.phone,
        password: this.formData.password,
        tipo_documento: this.tipoDocumento
      })
      .then(res => {
        this.loading = false;
        this.$swal({
          icon: "success",
          title: this.$t("notifications.titles.register").toString(),
          text: this.$t("notifications.success.signup", {
            email: this.formData.email
          }).toString(),
          confirmButtonText: this.$t("buttons.accept").toString()
        });
        this.$q.loading.hide();
        if (this.$router.currentRoute.name == "Talleres") {
          this.$router.go(0);
        } else {
          this.$router.push({ name: "Talleres" }).catch(err => {});
        }
      })
      .catch(error => {
        const message = error.response.data.message;
        const type = error.response.data.type;
        let text: string = "";
        if (message === "EMAIL_USED") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.REGISTERED_EMAIL", {
              email: this.formData.email
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "RUT_USED") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.RUT_USED", {
              rut: formattedRut
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "RUT_INVALID") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.RUT_INVALID", {
              rut: formattedRut
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "REGISTERED_PHONE") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.REGISTERED_PHONE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "REGISTERED_RUT") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.RUT_USED", {
              rut: formattedRut
            }).toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "REGISTERED_EMAIL") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.REGISTERED_EMAIL").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_EMAIL") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.INVALID_PARAM_EMAIL").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "USED_PHONE") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.REGISTERED_PHONE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "USED_EMAIL") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.REGISTERED_EMAIL").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_RUT") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.RUT_USED").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else if (message === "INVALID_PARAM_TELEFONO") {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.INVALID_PARAM_PHONE").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        } else {
          this.$swal({
            icon: "error",
            title: this.$t("notifications.titles.register").toString(),
            text: this.$t("notifications.error.ERROR_FORM").toString(),
            confirmButtonText: this.$t("buttons.accept").toString()
          });
        }
        this.$q.loading.hide();
        this.loading = false;
      });
  }

  private onCloseTerminos(info) {
    if (info.footer == true) {
      if (info.response == true) {
        this.customModel = "si";
        this.bTerminos = false;
      } else if (info.response == false) {
        this.customModel = "no";
        this.bTerminos = false;
      } else {
        this.bTerminos = false;
      }
    } else {
      this.bTerminos = false;
    }
  }

  private mostrarTerminos(footer) {
    this.terminosFooter = footer;
    this.bTerminos = true;
  }

  private validFecha(date: any) {
    if (date.getFullYear() < 1900) {
      return true;
    } else {
      return false;
    }
  }

  private validEmail(email) {
    // eslint-disable-next-line
    let emailRegex: RegExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (emailRegex.test(email) === true) {
      return true;
    } else {
      return false;
    }
  }

  public validateRut(newValue: string) {
    var texto = newValue;
    var tmpstr = "";
    for (let i = 0; i < texto.length; i++)
      if (
        texto.charAt(i) != " " &&
        texto.charAt(i) != "." &&
        texto.charAt(i) != "-"
      )
        tmpstr = tmpstr + texto.charAt(i);
    texto = tmpstr;
    var largo = texto.length;
    if (largo < 2) {
      return false;
    }
    for (let i = 0; i < largo; i++) {
      if (
        texto.charAt(i) != "0" &&
        texto.charAt(i) != "1" &&
        texto.charAt(i) != "2" &&
        texto.charAt(i) != "3" &&
        texto.charAt(i) != "4" &&
        texto.charAt(i) != "5" &&
        texto.charAt(i) != "6" &&
        texto.charAt(i) != "7" &&
        texto.charAt(i) != "8" &&
        texto.charAt(i) != "9" &&
        texto.charAt(i) != "k" &&
        texto.charAt(i) != "K"
      ) {
        return false;
      }
    }
    var invertido = "";
    for (let i = largo - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + texto.charAt(i);
    var dtexto = "";
    dtexto = dtexto + invertido.charAt(0);
    dtexto = dtexto + "-";
    var cnt = 0;
    for (let i = 1, j = 2; i < largo; i++, j++) {
      //alert("i=[" + i + "] j=[" + j +"]" );
      if (cnt == 3) {
        dtexto = dtexto + ".";
        j++;
        dtexto = dtexto + invertido.charAt(i);
        cnt = 1;
      } else {
        dtexto = dtexto + invertido.charAt(i);
        cnt++;
      }
    }
    invertido = "";
    for (let i = dtexto.length - 1, j = 0; i >= 0; i--, j++)
      invertido = invertido + dtexto.charAt(i);

    if (this.revisarDigito2(texto)) {
      return true;
    }
    return false;
  }
  public revisarDigito(dvr: string) {
    var dv = dvr + "";
    if (
      dv != "0" &&
      dv != "1" &&
      dv != "2" &&
      dv != "3" &&
      dv != "4" &&
      dv != "5" &&
      dv != "6" &&
      dv != "7" &&
      dv != "8" &&
      dv != "9" &&
      dv != "k" &&
      dv != "K"
    ) {
      return false;
    }
    return true;
  }
  public revisarDigito2(crut: string) {
    var largo = crut.length;
    if (largo < 2) {
      return false;
    }
    if (largo > 2) var rut = crut.substring(0, largo - 1);
    else rut = crut.charAt(0);
    var dv = crut.charAt(largo - 1);
    this.revisarDigito(dv);
    if (rut == null || dv == null) return 0;
    var dvr = "0";
    var suma = 0;
    var mul = 2;
    for (let i = rut.length - 1; i >= 0; i--) {
      suma = suma + parseInt(rut.charAt(i), 10) * mul;
      if (mul == 7) mul = 2;
      else mul++;
    }
    var res = suma % 11;
    if (res == 1) dvr = "k";
    else if (res == 0) dvr = "0";
    else {
      var dvi = 11 - res;
      dvr = dvi + "";
    }
    if (dvr != dv.toLowerCase()) {
      return false;
    }
    return true;
  }

  private checkFecha(d) {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      // it is a date
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
  private filterFnc(val, update, abort) {
    update(() => {
      const needle = val.toLowerCase();
      this.comunasListOptions = this.comunasList.filter(
        v => v.label.toLowerCase().indexOf(needle) > -1
      );
    });
  }

  private setModel(val, done) {
    let datos = {
      value: 0,
      label: val
    };
    for (let i = 0; i < this.streetsList.length; i++) {
      if (this.streetsList[i].label == val) {
        datos = {
          value: this.streetsList[i].value,
          label: this.streetsList[i].label
        };
        this.comunasListOptions.push(val);

        done(val, "toggle");
      }
    }
    this.dataCalle = datos;
  }

  private checkFechaMayorAHoy(d) {
    let hoy = new Date();
    if (d > hoy) {
      return true;
    } else {
      return false;
    }
  }
}
